<template>
  <v-container color="#f2f2f2">
    <v-row>
      <v-col cols=4 style="padding-bottom:0px !important;"><h3 class="primary--text" style="font-weight:400;">Home</h3></v-col>
      <v-col cols=8 style="padding-bottom:0px !important;" class="text-right"><p class="primary--text">{{ now_date }}</p></v-col>
      
      <v-col cols="12">
        <v-card color="#fff">
          <v-card-title class="text-h5"><strong>Status</strong> 
            <span elevation="2"
            style="border:1px solid #f2f2f2; border-radius:10px; color:#4F61AD; font-size:14px; position:absolute; right:0;margin-right:10px;padding:2px 10px; background:#f2f2f2">
            Current Active Projects: <strong>{{ projects.length }}</strong></span>
          </v-card-title>
          
          <v-card-text>
            <v-row>
              <v-col cols="6" style="padding:5px !important">
                <v-card color="#b2e5bd">
                  <v-card-title style="display:block !important; text-align:center !important;"><h2 class="green--text font-weight-regular">8/10</h2></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8" style="padding:5px !important">
                        <p color="primary--text" style="font-size:13px; margin-bottom:0px !important;">Project Tasks Completed</p>
                      </v-col>
                      <v-col cols="4" style="padding:0px !important">
                        <v-icon x-large class="green--text">mdi-check</v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              
              <v-col cols="6" style="padding:5px !important">
                <v-card color="#FFE082">
                  <v-card-title style="display:block !important; text-align:center !important;"><h2 class="primary--text font-weight-regular">3</h2></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8" style="padding:5px !important">
                        <p color="primary--text" style="font-size:13px; margin-bottom:0px !important;">Checklists to Review</p>
                      </v-col>
                      <v-col cols="4" style="padding:0px !important">
                        <v-icon x-large class="primary--text">mdi-clipboard-text</v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="6" style="padding:5px !important">
                <v-card color="#BBDEFB">
                  <v-card-title style="display:block !important; text-align:center !important;"><h2 class="primary--text font-weight-regular">4/13</h2></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8" style="padding:4px !important">
                        <p color="primary--text" style="font-size:13px; margin-bottom:0px !important;">EHS Checklists Completed</p>
                      </v-col>
                      <v-col cols="4" style="padding:0px !important">
                        <v-icon x-large class="primary--text">mdi-clipboard-check-multiple-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              
              <v-col cols="6" style="padding:5px !important">
                <v-card color="#BBDEFB">
                  <v-card-title style="display:block !important; text-align:center !important;"><h2 class="primary--text font-weight-regular">2/7</h2></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8" style="padding:5px !important">
                        <p color="primary--text" style="font-size:13px; margin-bottom:0px !important;">QA Checklists Completed</p>
                      </v-col>
                      <v-col cols="4" style="padding:0px !important">
                        <v-icon x-large class="primary--text">mdi-clipboard-check-multiple-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>
      
      <v-col cols="12">
        <v-card color="#fff">
          <v-card-title class="text-h5" style="padding: 1px 16px !important;"><strong>Projects</strong></v-card-title>
          <v-list style="padding-top:1px !important;">
            <div v-if="projects.length > 0">
            <v-list-item
            v-for="(project, index) in projects"
            :key="index"
            :style="{'background':project.colour}" style="margin:7px 10px; border-radius:10px;">
              <p style="color:#fff;margin:2px 0px;font-size:14px;"><strong>{{ project.project_code }}:</strong> {{ project.start_date }} - {{ project.end_date }}</p>
            </v-list-item>
            </div>
            <div v-else>
              <v-list-item-title style="color:#c53535;margin:5px 15px;">No Project found</v-list-item-title>
            </div>
          </v-list>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import date from 'date-and-time'
export default {
  name: 'Home',
  data: () => ({
    now_date: "Fresh"
  }),
  mounted () {
    let now  = new Date();
    let now_dates = date.format(now, 'ddd, MMM DD YYYY');
    this.now_date = now_dates 
    let user = JSON.parse(localStorage.getItem('user'))
    if(user == null){
      this.$router.push('/')
    }
    this.fetchAllProjects(user.id)
  },
  computed: {
    ... mapGetters ( {projects : "getAllProjects"}),
    count () {
      return this.$store.state.count
    }
  },
  methods:{
    ... mapActions (['fetchAllProjects']),
  }
}
</script>
